var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block py-8",attrs:{"data-cy":"2407"}},[_c('v-row',{attrs:{"data-cy":"2403"}},[_c('v-col',{staticClass:"align-self-end",attrs:{"data-cy":"2404"}},[_c('v-btn',{ref:"button_add_access",staticClass:"float-right",attrs:{"data-cy":"2405","color":"primary"},on:{"click":function($event){return _vm.$emit('addAccess')}}},[_c('v-icon',{attrs:{"data-cy":"2406","left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t("1122")))],1)],1)],1),_c('div',{staticClass:"d-block md-4 mt-4",attrs:{"data-cy":"2128"}},[_c('span',{attrs:{"data-cy":"2401"}},[_vm._v(_vm._s(_vm.$t("668")))])]),_c('v-data-table',{ref:"table_users_with_access",staticClass:"mt-4",attrs:{"data-cy":"2111","item-key":"id","items":_vm.pUsersWithAccess,"no-data-text":_vm.$t(_vm.$TABLES.USERS_WITH_ACCESS.noDataText),"items-per-page":-1,"headers":_vm.headersForUsersWithAccessTable,"loading":_vm.pIsLoadingAccesses,"fixed-header":"","multi-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"data-cy":"2112","size":"38","color":"light info lighten-4"}},[_c('span',{staticClass:"primary--text text--darken-1",attrs:{"data-cy":"2113"}},[_vm._v(_vm._s(_vm._f("initials")(item.name)))])]),_c('span',{staticClass:"ml-2 caption",attrs:{"data-cy":"2114"}},[_vm._v(_vm._s(item.name))]),(item.disabledButNotDeleted)?_c('span',{staticClass:"ml-1 info--text caption",attrs:{"data-cy":"2228"}},[_vm._v(_vm._s(_vm.$t("1131")))]):_vm._e()]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._l((item.roles),function(role){return [_c('v-chip',{staticClass:"mr-2",attrs:{"data-cy":"2115","color":"light info lighten-4","text-color":"primary darken-2","label":""}},[_vm._v(_vm._s(role.name))])]})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{ref:("button_edit_user_access_" + (item.id)),attrs:{"data-cy":"2410","color":"primary","text":""},on:{"click":function($event){return _vm.$emit('editUserAccess', item)}}},[_vm._v(_vm._s(_vm.$t("685")))])]}}])}),_c('div',{staticClass:"d-block md-4 mt-15",attrs:{"data-cy":"2402"}},[_c('span',{attrs:{"data-cy":"2116"}},[_vm._v(_vm._s(_vm.$t("671")))])]),_c('v-data-table',{ref:"table_groups_with_access",staticClass:"mt-4",attrs:{"data-cy":"2117","item-key":"id","items":_vm.pGroupsWithAccess,"no-data-text":_vm.$t(_vm.$TABLES.GROUPS_WITH_ACCESS.noDataText),"items-per-page":-1,"headers":_vm.headersForGroupsWithAccessTable,"loading":_vm.pIsLoadingAccesses,"expanded":_vm.expandedGroups,"fixed-header":"","multi-sort":"","hide-default-footer":"","show-expand":""},on:{"update:expanded":function($event){_vm.expandedGroups=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"data-cy":"2118","size":"38","color":"light info lighten-4"}},[_c('v-icon',{staticClass:"primary--text text--darken-1",attrs:{"data-cy":"2119","medium":""}},[_vm._v("mdi-group")])],1),_c('span',{staticClass:"ml-2 caption",attrs:{"data-cy":"2120"}},[_vm._v(_vm._s(item.name))])]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._l((item.roles),function(role){return [_c('v-chip',{staticClass:"mr-2",attrs:{"data-cy":"2121","color":"light info lighten-4","text-color":"primary darken-2","label":""}},[_vm._v(_vm._s(role.name))])]})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{ref:("button_edit_group_access_" + (item.id)),attrs:{"data-cy":"2409","color":"primary","text":""},on:{"click":function($event){return _vm.$emit('editGroupAccess', item)}}},[_vm._v(_vm._s(_vm.$t("685")))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.users)?_c('td',{staticClass:"pa-0",style:(("height: " + ((item.users.length) * 48) + "px")),attrs:{"data-cy":"2122","colspan":headers.length}},[_c('v-simple-table',{attrs:{"data-cy":"2123"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',{attrs:{"data-cy":"2124"}},_vm._l((item.users),function(user){return _c('tr',{key:user.id,attrs:{"data-cy":"2125"}},[_c('td',{staticClass:"pl-15",attrs:{"data-cy":"575"}},[_c('v-avatar',{attrs:{"data-cy":"2126","size":"38","color":"light info lighten-4"}},[_c('span',{staticClass:"primary--text text--darken-1",attrs:{"data-cy":"2127"}},[_vm._v(_vm._s(_vm._f("initials")(user.name)))])]),_c('span',{staticClass:"ml-2 caption",attrs:{"data-cy":"2128"}},[_vm._v(_vm._s(user.name))]),(user.disabledButNotDeleted)?_c('span',{staticClass:"ml-1 info--text caption",attrs:{"data-cy":"2211"}},[_vm._v(_vm._s(_vm.$t("1131")))]):_vm._e()],1)])}),0)]},proxy:true}],null,true)})],1):_vm._e()]}}]),model:{value:(_vm.expandedGroups),callback:function ($$v) {_vm.expandedGroups=$$v},expression:"expandedGroups"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }