import { getHeadersForTable } from "@/utils/table"
import { TABLE_NAMES, GET_CHANNEL_URL } from "@/constants"

export default {
  name: "DomainChannels",
  data: () => ({
  }),
  props: {
    pChannels        : Array,
    pClientName      : String,
    pIsDomainArchived: Boolean
  },
  computed: {
    headersForChannelsTable() {
      return getHeadersForTable(TABLE_NAMES.DOMAIN_CHANNELS, this.$t.bind(this))
    },
    footersForChannelsTable() {
      return {
        ...this.$TABLES.DOMAIN_CHANNELS.footer, ...{
          itemsPerPageText: this.$t(this.$TABLES.DOMAIN_CHANNELS.footer.itemsPerPageText)
        }
      }
    },
    itemsForChannelsTable() {
      return this.pChannels.map(channel => {
        return {
          id              : channel.id,
          name            : channel.name,
          domainId        : channel.domainId,
          url             : GET_CHANNEL_URL(this.pClientName, channel.name),
          organisationCode: channel.organisationCode
        }
      })
    }
  },
  methods: {
    clickRow(event, channel) {
      if (!event.target.className.includes("link")) {
        this.$emit("redirectToChannel", channel)
      }
    }
  }
}