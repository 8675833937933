import { GET_CHANNEL_URL } from "@/constants"

export default {
  name: "ChannelAdd",
  data: () => ({
    channel: {
      name       : "",
      displayName: ""
    },
    isChannelNameDuplicate       : false,
    isChannelDisplayNameDuplicate: false
  }),
  props: {
    pDomainId       : Number,
    pClientName     : String,
    pIsChannelAdded : Boolean,
    pIsAddingChannel: Boolean,
    pChannelAddError: Object
  },
  computed: {
    channelUrl() {
      return GET_CHANNEL_URL(this.pClientName, this.channel.name)
    }
  },
  methods: {
    addChannel() {
      this.$emit("addChannel", {
        ...this.channel,
        domainId: this.pDomainId
      })
    }
  },
  watch: {
    "channel.name": {
      handler: function() {
        if (this.isChannelNameDuplicate) {
          this.$emit("resetChannelAddError")
        }
      }
    },
    "channel.displayName": {
      handler: function() {
        if (this.isChannelDisplayNameDuplicate) {
          this.$emit("resetChannelAddError")
        }
      }
    },
    pIsChannelAdded: {
      handler: function(value) {
        if (value) {
          this.channel.name        = ""
          this.channel.displayName = ""
        }
      }
    },
    pChannelAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isChannelNameDuplicate = true
        } else {
          this.isChannelNameDuplicate = false
        }
        if (newValue?.field === "displayName" && newValue?.type === "duplicate") {
          this.isChannelDisplayNameDuplicate = true
        } else {
          this.isChannelDisplayNameDuplicate = false
        }
      }
    }
  }
}