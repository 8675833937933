import { getHeadersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"
export default {
  name: "AccessView",
  data: () => ({
    expandedGroups: []
  }),
  props: {
    pUsersWithAccess  : Array,
    pGroupsWithAccess : Array,
    pIsLoadingAccesses: Boolean
  },
  computed: {
    headersForUsersWithAccessTable() {
      return getHeadersForTable(TABLE_NAMES.USERS_WITH_ACCESS, this.$t.bind(this))
    },
    headersForGroupsWithAccessTable() {
      return getHeadersForTable(TABLE_NAMES.GROUPS_WITH_ACCESS, this.$t.bind(this))
    }
  }
}