import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"

export default {
  name: "AutomationsLeadingToDomain",

  props: {
    pAutomationsLeadingToDomain: Array,
    pChannels                  : Array,
    pDomains                   : Array,
    pFieldsV2                  : Array,
    pFormTemplates             : Array,
    pIsLoadingFormTemplates    : Boolean,
    pIsLoadingFieldsV2         : Boolean,
    pIsLoadingDomains          : Boolean,
    pIsLoadingChannels         : Boolean
  },

  computed: {
    automationsLeadingToDomainForTable() {
      const automationsLeadingToDomainForTableData = []
      for (const automationRule of this.pAutomationsLeadingToDomain) {
        const formNames        = []
        const fieldSystemNames = []
        const channel          = this.pChannels.find(channel => channel.id === automationRule.value.channelId)

        if (channel) {
          const domain = this.pDomains.find(domain => domain.id === channel.domainId)
          if (domain) {
            for (const condition of automationRule.value.conditions) {
              const formTemplate = this.pFormTemplates.find(formTemplate =>
                formTemplate.id === condition.formTemplateId
              )
              const field        = this.pFieldsV2.find(field =>
                field.id === condition.fieldId
              )

              if (formTemplate) {
                formNames.push(formTemplate.name)
              }

              if (field) {
                fieldSystemNames.push(field.systemName)
              }
            }
            automationsLeadingToDomainForTableData.push({
              channelId       : channel.id,
              domainName      : domain.name,
              channelName     : channel.displayName,
              formNames       : formNames.toString(),
              fieldSystemNames: fieldSystemNames.toString()
            })
          }
        }
      }
      return automationsLeadingToDomainForTableData

    },

    headersForTable() {
      return getHeadersForTable(TABLE_NAMES.AUTOMATIONS_LEADING_TO_DOMAIN, this.$t.bind(this))
    },

    footersForTable() {
      return getFootersForTable(TABLE_NAMES.AUTOMATIONS_LEADING_TO_DOMAIN, this.$t.bind(this))
    },

    isLoading() {
      return this.pIsLoadingFormTemplates || this.pIsLoadingFieldsV2
        || this.pIsLoadingDomains || this.pIsLoadingChannels
    }
  }
}