import { mapActions, mapGetters, mapMutations } from "vuex"
import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import ThemisDecision from "@/components/shared/decision"
import ThemisInput from "@/components/shared/input"
import ThemisDomainChannels from "@/components/domain/channels"
import ThemisChannelAdd from "@/components/channel/add"
import ThemisViewAccess from "@/components/access/view"
import ThemisUpdateAccess from "@/components/access/update"
import ThemisAutomationsLeadingToDomain from "@/components/domain/automations-leading-to-domain"
import { ANCHOR_LINKS } from "@/constants"

export default {
  name      : "Domain",
  components: {
    ThemisInputAppend,
    ThemisInputAppendOuter,
    ThemisDecision,
    ThemisInput,
    ThemisDomainChannels,
    ThemisChannelAdd,
    ThemisViewAccess,
    ThemisUpdateAccess,
    ThemisAutomationsLeadingToDomain
  },
  data: () => ({
    displaySaveAndCancelButton   : false,
    selectedTab                  : null,
    localDomain                  : undefined,
    filters                      : undefined,
    search                       : undefined,
    userOrGroup                  : undefined,
    accessToBeRemoved            : undefined,
    selectedRoles                : [],
    showDomainArchiveDialog      : false,
    showAddChannelDialog         : false,
    showUpdateAccessDialog       : false,
    isChannelNameDuplicate       : false,
    isChannelDisplayNameDuplicate: false,
    newlyAddedChannelName        : undefined,
    selectedUserForEditingAccess : undefined,
    selectedGroupForEditingAccess: undefined,
    tabs                         : {
      [ANCHOR_LINKS.CHANNELS]                          : 0,
      [ANCHOR_LINKS.DOMAIN_ACCESS]                     : 1,
      [ANCHOR_LINKS.AUTOMATIONS_LEADING_TO_THIS_DOMAIN]: 2
    }
  }),
  computed: {
    ...mapGetters({
      domains                                          : "domains/domains",
      channels                                         : "channels/channels",
      nameUpdateError                                  : "domains/nameUpdateError",
      isUpdatingName                                   : "domains/isUpdatingName",
      isNameUpdated                                    : "domains/isNameUpdated",
      isArchivingDomain                                : "domains/isArchivingDomain",
      isDomainArchived                                 : "domains/isDomainArchived",
      accesses                                         : "accesses/accesses",
      roles                                            : "roles/roles",
      users                                            : "users/users",
      groups                                           : "groups/groups",
      groupsUsers                                      : "groups/groupsUsers",
      isLoadingAccesses                                : "accesses/isLoadingAccesses",
      isAccessAdded                                    : "accesses/isAccessAdded",
      isAddingAccess                                   : "accesses/isAddingAccess",
      isAccessRemoved                                  : "accesses/isAccessRemoved",
      isRemovingAccess                                 : "accesses/isRemovingAccess",
      isLoadingChannel                                 : "channels/isLoadingChannels",
      isAddingChannel                                  : "channels/isAddingChannel",
      isChannelAdded                                   : "channels/isChannelAdded",
      channelAddError                                  : "channels/channelAddError",
      clientName                                       : "auth/clientName",
      archivingError                                   : "domains/archivingError",
      domainChangeOnSpeakUpIssueCreationRulesForDomains: "automations/domainChangeOnSpeakUpIssueCreationRulesForDomains",
      formTemplates                                    : "formTemplates/formTemplates",
      fieldsV2                                         : "fields/fieldsV2",
      isLoadingFormTemplates                           : "formTemplates/isLoadingFormTemplates",
      isLoadingFieldsV2                                : "fields/isLoadingFieldsV2",
      isLoadingDomains                                 : "domains/isLoadingDomains",
      isLoadingChannels                                : "channels/isLoadingChannels"
    }),

    domain() {
      return this.domains?.find(domain => domain.id === +this.$route.params.id)
    },
    rolesMap() {
      const rolesMap = new Object()
      for (const role of this.roles) {
        rolesMap[role.id] = role
      }
      return rolesMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.users) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    accessesOfDomain() {
      return this.accesses.filter(access => access.criteria?.domainId === this.domain?.id)
    },
    usersWithAccess() {
      const usersWithAccess = []
      const userRolesMap    = {}
      for (const access of this.accessesOfDomain) {
        const isAccessOnCurrentDomain = access.criteria?.domainId === this.domain?.id
        if (access.userId && isAccessOnCurrentDomain) {
          const role = {
            id      : access.roleId,
            name    : this.rolesMap[access.roleId]?.name,
            accessId: access.id,
            domain  : this.domain?.name
          }

          if (userRolesMap[access.userId]) {
            userRolesMap[access.userId].push(role)
          } else {
            userRolesMap[access.userId] = [role]
          }
        }
      }
      for (const [userId, roles] of Object.entries(userRolesMap)) {
        const user = this.usersMap[userId]
        let name
        let disabledButNotDeleted
        if (user) {
          name                  = user.name,
          disabledButNotDeleted = user.disabledButNotDeleted
        }
        usersWithAccess.push({
          id: +userId,
          name,
          disabledButNotDeleted,
          roles
        })
      }
      return usersWithAccess
    },
    groupsWithAccess() {
      const groupsWithAccess = []
      const groupRolesMap    = {}
      for (const access of this.accessesOfDomain) {
        const isAccessOnCurrentDomain = access.criteria?.domainId === this.domain?.id
        if (access.groupId && isAccessOnCurrentDomain) {
          const role = {
            id      : access.roleId,
            name    : this.rolesMap[access.roleId]?.name,
            accessId: access.id,
            domain  : this.domain.name
          }

          if (groupRolesMap[access.groupId]) {
            groupRolesMap[access.groupId].push(role)
          } else {
            groupRolesMap[access.groupId] = [role]
          }
        }
      }
      for (const [groupId, roles] of Object.entries(groupRolesMap)) {
        const users = this.groupsUsers[groupId]?.map(userId => ({
          id                   : userId,
          name                 : this.usersMap[userId]?.name,
          disabledButNotDeleted: this.usersMap[userId]?.disabledButNotDeleted
        }))
        groupsWithAccess.push({
          id  : +groupId,
          name: this.groupsMap[groupId]?.name,
          users,
          roles
        })
      }
      return groupsWithAccess
    },
    usersWithoutAccess() {
      return this.users.filter(user =>
        user.enabled && !this.usersWithAccess.find(userWithAccess =>
          user.id === userWithAccess.id
        )
      )
    },
    groupsWithoutAccess() {
      return this.groups.filter(group =>
        !this.groupsWithAccess.find(groupWithAccess =>
          group.id === groupWithAccess.id)
      )
    },
    automationsLeadingToDomain() {
      return this.domainChangeOnSpeakUpIssueCreationRulesForDomains[+this.$route.params.id]
    },
    isAnyDomainChannelEnabled() {
      for (const channel of this.domainChannels) {
        if (channel.enabled) {
          return true
        }
      }
      return false
    },
    isArchiveDisabled() {
      return !!this.automationsLeadingToDomain || this.isAnyDomainChannelEnabled
    },

    currentDomainRoleIdsForSelectedUser() {
      const roleIds = new Set()
      if (this.userOrGroup) {
        for (const access of this.accesses) {
          const [type, id] = this.userOrGroup.split("_")
          if (type === "group") {
            if (access.groupId === +id && access.criteria?.domainId === +this.$route.params.id) {
              roleIds.add(access.roleId)
            }
          } else {
            if (access.userId === +id && access.criteria?.domainId === +this.$route.params.id) {
              roleIds.add(access.roleId)
            }
          }
        }
      }
      return Array.from(roleIds)
    },
    domainChannels() {
      return this.channels?.filter(channel => channel.domainId === +this.$route.params.id)
    },
    isNameChanged() {
      return this.domain?.name !== this.localDomain?.name
    },
    isNameDuplicate() {
      return this.nameUpdateError?.type === "duplicate"
    }
  },
  methods: {
    ...mapActions({
      updateDomain: "domains/updateDomain",
      addAccess   : "accesses/addAccess",
      removeAccess: "accesses/removeAccess",
      notify      : "shared/notify",
      addChannel  : "channels/addChannel"
    }),
    ...mapMutations({
      resetDomainUpdateError: "domains/resetDomainUpdateError",
      setChannelAddError    : "channels/setChannelAddError"
    }),
    customFilterForTable(value) {
      return this.filters.findIndex(element => value?.toString().includes(element)) + 1
    },
    redirectToChannel(channel) {
      this.$router.push({
        name  : "domain-channel",
        params: {
          domainId: this.domain.id,
          id      : channel.id
        }
      })
    },
    handleAddChannelEvent(channel) {
      this.newlyAddedChannelName = channel.name
      this.addChannel(channel)
    },
    verifyAndUpdateDomain(){
      if (this.localDomain.name && this.isNameChanged) {
        this.updateDomain({
          id  : this.localDomain.id,
          name: this.localDomain.name
        })
      }
    },
    handleNameInputOnBlurEvent(onBlur) {
      onBlur()
      this.verifyAndUpdateDomain()
    },
    handleDomainNameInputOnEnter() {
      this.$refs.input_domain_name.blur()
    },
    resetName() {
      this.resetDomainUpdateError(["name"])
    },
    handleCloseUpdateAccess() {
      this.showUpdateAccessDialog        = false
      this.selectedGroupForEditingAccess = undefined
      this.selectedUserForEditingAccess  = undefined
    },
    handleAccessAdded(userOrGroup) {
      const [type, id]            = userOrGroup.split("_")
      this.showUpdateAccessDialog = false
      this.notify({
        type      : "success",
        text      : "1124",
        parameters: {
          name: type === "user" ? this.usersMap[id].name : this.groupsMap[id].name
        }
      })
    },
    handleEditUserAccess(user) {
      this.selectedUserForEditingAccess = user
      this.showUpdateAccessDialog       = true
    },
    handleEditGroupAccess(group) {
      this.selectedGroupForEditingAccess = group
      this.showUpdateAccessDialog        = true
    },
    handleAccessUpdated(userOrGroup) {
      this.showUpdateAccessDialog        = false
      this.selectedUserForEditingAccess  = undefined
      this.selectedGroupForEditingAccess = undefined
      this.notify({
        type      : "success",
        text      : "695",
        parameters: {
          name: userOrGroup.name
        }
      })
    },
    handleTabClickEvent(hash) {
      this.selectedTab = this.tabs[hash] ||  this.tabs[ANCHOR_LINKS.CHANNELS]
      if (this.$route.hash !== hash) {
        this.$router.push({ hash })
      }
    }
  },
  watch: {
    domain: {
      immediate: true,
      handler  : function(newValue) {
        if (!this.localDomain) {
          this.localDomain = { ...newValue }
        }
      }
    },
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    },
    isArchivingDomain: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.ARCHIVE_DOMAIN.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.ARCHIVE_DOMAIN.pActions[0].buttonProps.disabled = newValue
      }
    },
    isDomainArchived: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.localDomain.archived    = true
          this.showDomainArchiveDialog = false
          this.notify({
            type      : "success",
            text      : "441",
            parameters: {
              name: this.localDomain.name
            }
          })
        }
      }
    },
    isChannelAdded: {
      handler: function(value) {
        if (value) {
          this.showAddChannelDialog = false
          this.notify({
            type      : "success",
            text      : "775",
            parameters: {
              name: this.newlyAddedChannelName
            }
          })
          this.newlyAddedChannelName = ""
        }
      }
    },
    channelAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.field === "name" && newValue?.type === "duplicate") {
          this.isChannelNameDuplicate = true
        } else {
          this.isChannelNameDuplicate = false
        }
        if (newValue?.field === "displayName" && newValue?.type === "duplicate") {
          this.isChannelDisplayNameDuplicate = true
        } else {
          this.isChannelDisplayNameDuplicate = false
        }
      }
    },
    archivingError: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type      : "error",
            text      : "502",
            parameters: {
              name: this.localDomain.name
            }
          })
          this.showDomainArchiveDialog = false
        }
      }
    },
    isNameUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.notify({
            type      : "success",
            text      : "1121",
            parameters: {
              newName: this.domain.name
            }
          })
        }
      }
    },
    "$route.hash": {
      immediate: true,
      handler  : function(hash) {
        if(hash){
          this.handleTabClickEvent(hash)
        }
      }
    }
  }
}