import { ROLE_ASSIGNMENT_CATEGORY } from "@/constants"

export default {
  name      : "UpdateAccess",
  components: {
  },
  props: {
    pIssue              : Object,
    pDomain             : Object,
    pUsersWithoutAccess : Array,
    pGroupsWithoutAccess: Array,
    pUsersWithAccess    : Array,
    pGroupsWithAccess   : Array,
    pRoles              : Array,
    pSelectedUser       : Object,
    pSelectedGroup      : Object
  },
  data: () => ({
    showUpdateAccessDialog             : false,
    selectedRoles                      : [],
    selectedUserOrGroup                : undefined,
    userId                             : undefined,
    groupId                            : undefined,
    rolesOnDomainForSelectedUserOrGroup: [],
    rolesOnIssueForSelectedUserOrGroup : [],
    domain                             : undefined,
    rolesToBeAdded                     : [],
    rolesToBeRemoved                   : [],
    addingAccess                       : false,
    updatingAccess                     : false,
    existingSelectedRoles              : []
  }),
  computed: {
    roles() {
      return this.pRoles.filter(role => role.issue)
    },
    usersAndGroupsWithoutAccess() {
      const users  =  this.pUsersWithoutAccess.map(user => ({
        id     : `${ROLE_ASSIGNMENT_CATEGORY.USER}_${user.id}`,
        name   : user.name,
        isGroup: false
      }))
      const groups = this.pGroupsWithoutAccess.map(group => ({
        id     : `${ROLE_ASSIGNMENT_CATEGORY.GROUP}_${group.id}`,
        name   : group.name,
        isGroup: true
      }))
      return [{ "header": this.$t("414") }].concat(users).concat([{ "header": this.$t("415") }]).concat(groups)
    },
    updateAccessMode() {
      return !!(this.pSelectedUser || this.pSelectedGroup)
    },
    accessAdded() {
      if (this.addingAccess) {
        const userWithAccess = this.pUsersWithAccess.find(user => user.id === this.userId)
        if (userWithAccess) {
          return this.selectedRoles.every(
            selectedRole => userWithAccess.roles.find(
              role => role.id === selectedRole
            )
          )
        }
        const groupWithAccess = this.pGroupsWithAccess.find(group => group.id === this.groupId)
        if (groupWithAccess) {
          return this.selectedRoles.every(
            selectedRole => groupWithAccess.roles.find(
              role => role.id === selectedRole
            )
          )
        }
      }
      return false
    },
    accessUpdated() {
      if (this.updatingAccess) {
        let accessAdded
        let accessRemoved
        let userOrGroupWithAccess
        if (this.pSelectedUser) {
          userOrGroupWithAccess = this.pUsersWithAccess.find(user => user.id === this.pSelectedUser.id)
        } else if (this.pSelectedGroup) {
          userOrGroupWithAccess = this.pGroupsWithAccess.find(group => group.id === this.pSelectedGroup.id)
        }
        if (userOrGroupWithAccess) {
          if (this.rolesToBeAdded.length) {
            accessAdded = this.rolesToBeAdded.every(
              roleToBeAdded => userOrGroupWithAccess.roles.find(
                role => {
                  if (role.id === roleToBeAdded) {
                    return (this.pIssue && !role.domain)
                      || (this.pDomain && role.domain)
                  }
                }
              )
            )
          } else {
            accessAdded = true
          }
          if (this.rolesToBeRemoved.length) {
            accessRemoved = !this.rolesToBeRemoved.find(
              roleToBeRemoved => userOrGroupWithAccess.roles.find(
                role => role.accessId === roleToBeRemoved.accessId
              )
            )
          } else {
            accessRemoved = true
          }
        } else {
          accessAdded = !this.rolesToBeAdded.length
          if (this.pIssue) {
            accessRemoved = this.rolesToBeRemoved.length === this.rolesOnIssueForSelectedUserOrGroup.length
          } else {
            accessRemoved = this.rolesToBeRemoved.length === this.rolesOnDomainForSelectedUserOrGroup.length
          }

        }
        return accessAdded && accessRemoved
      }
    }
  },
  methods: {
    updateAccesses() {
      this.updatingAccess = true
      for (const role of this.rolesToBeAdded) {
        this.$emit("addAccess", {
          userId  : this.pSelectedUser?.id,
          groupId : this.pSelectedGroup?.id,
          roleId  : role,
          criteria: {
            id      : this.pIssue?.id,
            domainId: this.pDomain?.id
          }
        })
      }
      for (const access of this.rolesToBeRemoved) {
        this.$emit("removeAccess", access.accessId)
      }
    },
    addAccesses() {
      this.addingAccess = true
      for (const role of this.selectedRoles) {
        this.$emit("addAccess", {
          roleId  : role,
          criteria: {
            id      : this.pIssue?.id,
            domainId: this.pDomain?.id
          },
          userId : this.userId,
          groupId: this.groupId
        })
      }
    },
    clearData() {
      this.selectedRoles       = this.pIssue ?
        this.rolesOnIssueForSelectedUserOrGroup : this.rolesOnDomainForSelectedUserOrGroup
      this.selectedUserOrGroup = undefined
    },
    handleCancel() {
      this.clearData()
      this.$emit("close")
    },
    processSelectedUserOrGroup(selectedUserOrGroup) {
      this.rolesOnDomainForSelectedUserOrGroup = []
      this.rolesOnIssueForSelectedUserOrGroup  = []
      this.selectedRoles                       = []
      this.domain                              = undefined
      if (selectedUserOrGroup) {
        for (const role of selectedUserOrGroup.roles) {
          if (role.domain) {
            this.rolesOnDomainForSelectedUserOrGroup.push(role)
            this.domain = role.domain
            if (this.pDomain) {
              this.selectedRoles.push(role.id)
            }
          } else {
            this.rolesOnIssueForSelectedUserOrGroup.push(role)
            if (this.pIssue) {
              this.selectedRoles.push(role.id)
            }
          }
        }
        this.existingSelectedRoles = [...this.selectedRoles]
      }
    },
    disableRoleSelection(roleId) {
      // For disabled users - disable selection of new roles
      if (this.pSelectedUser && this.pSelectedUser.disabledButNotDeleted) {
        return !this.existingSelectedRoles.find(selectedRoleId => selectedRoleId === roleId)
      }

      return false
    }
  },
  watch: {
    selectedUserOrGroup: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const [type, id] = this.selectedUserOrGroup.split("_")
          if (type === ROLE_ASSIGNMENT_CATEGORY.USER) {
            this.userId = +id
          } else {
            this.groupId = +id
          }
        } else {
          this.userId  = undefined
          this.groupId = undefined
        }
      }
    },
    accessAdded: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.$emit("accessAdded", this.selectedUserOrGroup)
          this.addingAccess = false
          this.clearData()
        }
      }
    },
    accessUpdated: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.$emit("accessUpdated", this.pSelectedUser || this.pSelectedGroup)
          this.updatingAccess = false
          this.clearData()
        }
      }
    },
    updateAccessMode: {
      immediate: true,
      handler  : function() {
        this.processSelectedUserOrGroup(this.pSelectedGroup || this.pSelectedUser)
      }
    },
    selectedRoles: {
      immediate: true,
      handler  : function(newValue) {
        const rolesForSelectedUserOrGroup = this.pIssue ?
          this.rolesOnIssueForSelectedUserOrGroup : this.rolesOnDomainForSelectedUserOrGroup
        if (rolesForSelectedUserOrGroup.length) {
          this.rolesToBeRemoved = rolesForSelectedUserOrGroup.filter(
            roleForSelectedUserOrGroup => !newValue.includes(roleForSelectedUserOrGroup.id)
          )
        } else {
          this.rolesToBeRemoved = []
        }
        if (newValue) {
          this.rolesToBeAdded = newValue.filter(
            selectedRole => !rolesForSelectedUserOrGroup.find(
              roleForSelectedUserOrGroup => roleForSelectedUserOrGroup.id === selectedRole
            )
          )
        } else {
          this.rolesToBeAdded = []
        }
      }
    }
  }
}